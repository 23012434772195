import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { CommonModule } from "@angular/common";
import { DialogComponent } from '../../dialog/dialog.component';
import { UserRepositoryService } from 'src/app/repository/user-repository.service';
import { SubscriberListComponent } from 'src/app/components/subscriber-list/subscriber-list.component';
import { Router } from '@angular/router';
import { SubscriptionsService } from 'src/app/service/subscriptions.service';
import { Subscription, map, take } from 'rxjs';
import {UserShortModelData} from "desiren-core-lib/lib/types/users";
import {TranslocoModule} from "@ngneat/transloco";

@Component({
  selector: 'app-profile-stats',
  standalone: true,
  imports: [
    CommonModule,
    DialogComponent,
    SubscriberListComponent,
    TranslocoModule
  ],
  templateUrl: './profile-stats.component.html',
  styleUrl: './profile-stats.component.scss'
})
export class ProfileStatsComponent implements OnInit, OnDestroy {
  @Input() views?: string;
  @Input() followers?: string;
  @Input() posts?: string;
  @Input() videos?: string;
  @Input() follows?: string;
  @Input() isCreator?: boolean = false;
  @Input() isMyProfile?: boolean = false;
  @ViewChild('statsDialogRef') statsDialogRef: DialogComponent | null = null;
  public listToShow: UserShortModelData[] = [];
  public subscribesList: UserShortModelData[] = [];
  public subscribersList: UserShortModelData[] = [];
  public hideAdditionalStat: boolean;
  private subscribes$: Subscription;
  private subscribers$: Subscription;
  private creatorId: string;

  constructor(
    private userRepo: UserRepositoryService,
    private router: Router,
    private subscriptionsService: SubscriptionsService,
  ) {

  }
  ngOnInit() {
    if (this.router.url.includes('my-profile') || this.isMyProfile) {
      this.hideAdditionalStat = true;
      this.creatorId = this.userRepo.meLazy().cache.id;
      this.loadSubscribersList();
    }
  }

  ngOnDestroy(): void {
    this.subscribes$?.unsubscribe();
    this.subscribers$?.unsubscribe();
  }

  loadSubscribersList() {
    this.subscribes$ = this.subscriptionsService.getSubscribes(this.creatorId)
      .pipe(
        take(1),
        map(data => data.map(item => item.user))
      )
      .subscribe(data => this.subscribesList = data);
    if(this.userRepo.meLazy().cache.isCreator) {
      this.subscribers$ = this.subscriptionsService.getSubscribers(this.creatorId)
      .pipe(
        take(1),
        map(data => data.map(item => item.user)))
      .subscribe(data => this.subscribersList = data);
    }
  }

  showFollowers() {
    if (!this.subscribersList.length) return;
    this.listToShow = this.subscribersList;
    this.statsDialogRef?.open();
  }

  showFollows() {
    if (!this.subscribesList.length) return;
    this.listToShow = this.subscribesList;
    this.statsDialogRef?.open();
  }

  public routeToProfile(event: UserShortModelData): void {
    this.statsDialogRef.close();
    this.router.navigate([`/profile/${event.id}`]);
  }
}
